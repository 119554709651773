export class AuthPayloadSessionAuth {
  static typename = 'AuthPayloadSessionAuth';
  // empty class, only neede for type check
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  getTypename(): string {
    return AuthPayloadSessionAuth.typename;
  }
}
