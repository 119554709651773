export class AuthPayloadRetireDevice {
  static typename = 'AuthPayloadRetireDevice';
  customerDeviceName: string;
  serial: string;

  constructor(customerDeviceName: string, serial: string) {
    this.customerDeviceName = customerDeviceName;
    this.serial = serial;
  }

  getTypename(): string {
    return AuthPayloadRetireDevice.typename;
  }
}
