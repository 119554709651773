export enum HighRiskCountries {
  BS,
  BW,
  KH,
  KP,
  ET,
  GH,
  IR,
  PK,
  PA,
  LK,
  SY,
  TT,
  TN,
  YE,
}
