import { Asset } from '../asset.model';
import { ExecutionType } from '../order-data.model';
import { Quote } from '../quote.model';

export class ProtectOrderModel {
  public stop?: number;
  public validity?: Date;
  public distance: number;

  constructor(distance: number) {
    this.distance = distance;
  }
}

export interface ProtectOrderParams {
  asset: Asset;
  quantity: number;
  execType: ExecutionType;
  direction: string;
  quote: Quote;
}
