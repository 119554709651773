import { MoneyTransaction } from './money-transaction-model';

export class AuthPayloadMoneyTx {
  static typename = 'AuthPayloadMoneyTx';
  data: MoneyTransaction;

  constructor(data: MoneyTransaction) {
    this.data = data;
  }

  getTypename(): string {
    return AuthPayloadMoneyTx.typename;
  }
}
