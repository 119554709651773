export enum TelekursPeriod {
  Intraday = 'Intraday',
  OneWeek = 'OneWeek',
  OneMonth = 'OneMonth',
  SixMonths = 'SixMonths',
  OneYear = 'OneYear',
  TwoYears = 'TwoYears',
  FiveYears = 'FiveYears',
  Maximum = 'Maximum',
  CurrentYear = 'CurrentYear',
  BestAccuracy = 'BestAccuracy',
}
