import { enableProdMode } from '@angular/core';
import { initSentry } from '@zero/shared/util-services';
import { Auth } from 'aws-amplify';
import { environment } from 'environments/environment';

Auth.configure(environment.amplifyconfig);

initSentry();

if (environment.production) {
  enableProdMode();
}

import('./bootstrap').catch((err) => console.error(err));
