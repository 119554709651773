export class MoneyTransaction {
  id: number;
  amount: number;
  purpose: string;
  customerId: string;
  recipientAccountIBAN: string;
  bookingDate: string;
  createdDateTime: string;
  valutaDate: string;
  matchedImportStorno: string;
  status: string;
  importMatchDate: string;
  transactionKey: string;
  isStorno: boolean;
  displayStatus: string;

  // TODO: api should deliver these props
  type: string;
  owner: string;
  iban: string;
  bic: string;
  reference: string;
}
