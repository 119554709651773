import { InstrumentType } from '../../instrumenttype.enum';
import { ChartSerie, ChartSerieType } from './chart-serie.entity';
import { ChartSetting } from './chart-setting.entity';
import { ChartType } from './chart-type.enum';
import { TelekursPeriod } from './telekurs-period.entity';
import { Tick } from './tick.entity';

export class InstrumentChartModel {
  public series: ChartSerie[];
  public isLoaded = false;
  public isin: string;
  public tick: Tick;
  public prevDayClose: number;
  public prevDayBid: number;
  public prevDayAsk: number;
  public isTick: boolean;
  public hasError: boolean;
  public hasSetDataInitially = false;
  public displaySettings = true;
  public isTradingTime = true;
  public currentSeries: ChartSerieType[] = ['last'];
  public instrumentType: InstrumentType;
  public displaySeriesToggles = false;
  public isBestAccuracyPossible = false;
  public isOTC = false;

  public periods: ChartSetting[] = [
    { value: TelekursPeriod.BestAccuracy },
    { value: TelekursPeriod.OneWeek },
    { value: TelekursPeriod.OneMonth },
    { value: TelekursPeriod.SixMonths },
    { value: TelekursPeriod.CurrentYear },
    { value: TelekursPeriod.OneYear, isActive: true },
    { value: TelekursPeriod.TwoYears },
    { value: TelekursPeriod.Maximum },
  ];

  public types: ChartSetting[] = [
    { value: ChartType.Line },
    { value: ChartType.Mountain, isActive: true },
    { value: ChartType.HLC },
    { value: ChartType.Candle },
  ];

  public getActiveType = (): ChartType => this.types.find(({ isActive }) => isActive)?.value as ChartType;

  public getActivePeriod = (): string => this.periods.find(({ isActive }) => isActive)?.value;
}
