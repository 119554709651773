import { TicketData } from './ticket-data.model';

export class TicketResponse {
  list: Ticket[];
  hasNext: boolean;
}

export class Ticket {
  id: number;
  subject: string;
  type: string;
  deskTicket: any;
  deskThreads: any[];
  deskComments: any[];
  ticketData: TicketData;
  created: Date;
  updated?: Date;
  customerId: string;
  status: TicketStatus;
  author?: TicketAuthor;
}

export type TicketStatus = 'OPEN' | 'CLOSED' | 'PREPARED' | 'CUSTOMER_REPLY';
export type TicketAuthor = 'Du' | 'System';
