import { SafeHtml } from '@angular/platform-browser';
import { TicketThreadEntryAttachment } from './ticket-thread-entry-attachment.model';

export class TicketThreadEntry {
  public dateTime: string; // ISO Date/Time
  public content: SafeHtml;
  public author: string;
  public attachments: TicketThreadEntryAttachment[];
  public entryType: 'ticket' | 'response' | 'comment';
  public resolutionContent: SafeHtml;
  public get date(): Date {
    return new Date(this.dateTime);
  }

  constructor(dateTime: string, content: SafeHtml, author: string, entryType: 'ticket' | 'response' | 'comment') {
    this.dateTime = dateTime;
    this.content = content;
    this.author = author;
    this.entryType = entryType;
  }
}
