import { HttpErrorResponse, HttpParams } from '@angular/common/http';

/*
  inspired by https://stackoverflow.com/questions/46075602/how-to-pass-a-param-to-httpinterceptor
 */
export class HttpInterceptorParams extends HttpParams {
  constructor(
    public interceptorConfig: {
      skipInterceptorErrorHandler: boolean;
      useErrorHandler?: (err: HttpErrorResponse) => void;
    },
    params?: Record<string, string | string[]>,
  ) {
    super({ fromObject: params } as any);
  }
}
