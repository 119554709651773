import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'environments/environment';

export function provideSentry() {
  return [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
}

export function initSentry() {
  environment.sentryDSN &&
    Sentry.init({
      dsn: environment.sentryDSN,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', environment.apihost],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
}
