export class TicketThreadEntryAttachment {
  public name: string;
  public attachmentId: string;
  public attachmentSize: number;
  public threadId: string;
  public isAgentAttachment: boolean;

  constructor(name: string, attachmentId: string, attachmentSize: number, threadId?: string, isAgentAttachment?: boolean) {
    this.name = name;
    this.attachmentId = attachmentId;
    this.attachmentSize = attachmentSize;
    this.threadId = threadId;
    this.isAgentAttachment = isAgentAttachment;
  }
}
