export class AuthEmailChange {
  static readonly typename = 'AuthEmailChange';

  customerId: string;
  type: string;
  emailChangeData: {
    additionalProperties: {
      requestId: string;
    };
  };

  constructor() {
    this.type = 'EMAIL';
    this.emailChangeData = {
      additionalProperties: {
        requestId: null,
      },
    };
  }

  getTypename(): string {
    return AuthEmailChange.typename;
  }
}
