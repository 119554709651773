import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBackendResponse, RequestTicketAuthResponse, Ticket, TicketVerificationRequest } from '@zero/shared/util-models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpResponseHandleHelper } from '../http/http-response-handle-helper';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private http: HttpClient) {}

  public requestTAN(c: Ticket, tanViaTTS?: boolean): Observable<IBackendResponse<Ticket>> {
    return HttpResponseHandleHelper.handleResponse(
      this.http.post<Ticket>(`${environment.apihost + environment.ticketUrl}/requesttan`, c, {
        params: HttpResponseHandleHelper.skipGlobalHandler().append('useSpeech', String(tanViaTTS)),
      }),
    );
  }

  public verifyTicket(ticket: Ticket, tan?: string, authorizationId?: string): Observable<IBackendResponse<Ticket>> {
    const requestPayload = new TicketVerificationRequest(ticket, tan, authorizationId);
    return HttpResponseHandleHelper.handleResponse(
      this.http.post<Ticket>(`${environment.apihost + environment.ticketUrl}/verify`, requestPayload, {
        params: HttpResponseHandleHelper.skipGlobalHandler(),
      }),
    );
  }

  public verifyChangeMobileNr(ticket: Ticket, tan: string): Observable<IBackendResponse<boolean>> {
    const enpoint = '/verifychangedmobilenr';
    const requestPayload = new TicketVerificationRequest(ticket, tan, undefined);
    return HttpResponseHandleHelper.handleResponse(
      this.http.post<boolean>(environment.apihost + environment.ticketUrl + enpoint, requestPayload, {
        params: HttpResponseHandleHelper.skipGlobalHandler(),
      }),
    );
  }

  public requestAppAuth(serial: string, ticketToAuthorize: Ticket): Observable<IBackendResponse<RequestTicketAuthResponse>> {
    return HttpResponseHandleHelper.handleResponse(
      this.http.post<RequestTicketAuthResponse>(
        `${environment.apihost + environment.ticketUrl}/requestauth`,
        {
          ticket: ticketToAuthorize,
          serialForAppAuth: serial,
        },
        { params: HttpResponseHandleHelper.skipGlobalHandler() },
      ),
    );
  }
}
