export class AuthPayloadEnrollDevice {
  static typename = 'AuthPayloadEnrollDevice';
  deviceName: string;

  constructor(deviceName: string) {
    this.deviceName = deviceName;
  }

  getTypename(): string {
    return AuthPayloadEnrollDevice.typename;
  }
}
