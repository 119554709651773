import { Ticket } from './ticket.model';

export class TicketVerificationRequest {
  ticket: Ticket;
  tan: string;
  authorizationId: string;

  constructor(ticket: Ticket, tan: string, authorizationId: string) {
    this.ticket = ticket;
    this.tan = tan;
    this.authorizationId = authorizationId;
  }
}
