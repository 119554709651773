import { OrderData } from './order-data.model';

export class OrderPlacement {
  status: string;
  orderNumber: number;
  order: OrderData;

  constructor(status: string, orderNumber: number) {
    this.status = status;
    this.orderNumber = orderNumber;
  }
}
