import { Ticket } from './ticket.model';

export class AuthPayloadTicket {
  static typename = 'AuthPayloadTicket';
  data: Ticket;
  constructor(ticket: Ticket) {
    this.data = ticket;
  }

  getTypename(): string {
    return AuthPayloadTicket.typename;
  }
}
