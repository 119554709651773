import { ValuesEntity } from './base-data.entity';

export class DividendsData {
  public headline?: string;
  public get dividends(): DividendItem[] {
    return this._dividends.slice(0, 6);
  }

  private _dividends: DividendItem[] = [];

  constructor(headline: string, values: ValuesEntity[]) {
    this.headline = headline;
    for (const value of values) {
      const year = value.key.replace('e', '');
      if (!year) {
        continue;
      }

      // ignore future dividends
      if (Number(year) <= new Date().getFullYear()) {
        this._dividends.push(new DividendItem(value));
      }
    }
  }
}

export class DividendItem {
  public yieldPercent?: number;
  public yieldAbs?: number;
  public year?: string;
  public symbol?: string;

  constructor(value: ValuesEntity) {
    this.yieldPercent = value.percent;
    this.yieldAbs = value.current;
    this.year = value.key;
    this.symbol = value.symbol;
  }
}
