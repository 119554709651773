import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private _injectedScripts = new Map<string, HTMLScriptElement>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public injectScript(url: string, renderer: Renderer2, timeout?: number): Promise<HTMLScriptElement> {
    return new Promise<HTMLScriptElement>((resolve, reject) => {
      if (this._injectedScripts.has(url)) {
        resolve(this._injectedScripts.get(url));
        return;
      }

      let timer: NodeJS.Timeout;

      if (timeout) {
        timer = setTimeout(() => {
          reject(new Error(`Script injection timed out after ${timeout}ms`));
        }, 10000);
      }

      const script: HTMLScriptElement = renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      renderer.appendChild(this.document.body, script);

      script.onload = () => {
        this._injectedScripts.set(url, script);
        resolve(script);
        clearTimeout(timer);
      };
      script.onerror = () => {
        reject(new Error(`Failed to load script ${url}`));
        clearTimeout(timer);
      };
    });
  }

  public removeScript(url: string, renderer: Renderer2) {
    if (this._injectedScripts.has(url)) {
      const script = this._injectedScripts.get(url);
      renderer.removeChild(this.document.body, script);
      this._injectedScripts.delete(url);
    }
  }
}
