import { TicketHistoryEntry } from './ticket-history-entry.model';

export class TicketData {
  static readonly ATTR_NEW_MOBILENR = 'ATTR_NEW_MOBILENR';
  static readonly ATTR_NEW_REFACCOUNT_IBAN = 'ATTR_NEW_REFACCOUNT_IBAN';
  static readonly ATTR_NEW_REFACCOUNT_DIRECT_DEBIT_MANDATE = 'ATTR_NEW_REFACCOUNT_DIRECT_DEBIT_MANDATE';
  static readonly ATTR_NEW_PORTFOLIO_REPORT_DUE_DATE = 'ATTR_NEW_PORTFOLIO_REPORT_DUE_DATE';
  static readonly ATTR_TAN = 'ATTR_TAN';

  static readonly ATTR_NEW_STREET = 'ATTR_NEW_STREET';
  static readonly ATTR_NEW_STREET2 = 'ATTR_NEW_STREET2';
  static readonly ATTR_NEW_STREETNO = 'ATTR_NEW_STREETNO';
  static readonly ATTR_NEW_STREETNO2 = 'ATTR_NEW_STREETNO2';
  static readonly ATTR_NEW_POSTALCODE = 'ATTR_NEW_POSTALCODE';
  static readonly ATTR_NEW_CITY = 'ATTR_NEW_CITY';
  static readonly ATTR_NEW_COUNTRY = 'ATTR_NEW_COUNTRY';
  static readonly ATTR_NEW_TAX_COUNTRY_1 = 'ATTR_NEW_TAX_COUNTRY_1';
  static readonly ATTR_NEW_TAX_COUNTRY_2 = 'ATTR_NEW_TAX_COUNTRY_2';
  static readonly ATTR_NEW_TAX_COUNTRY_3 = 'ATTR_NEW_TAX_COUNTRY_3';
  static readonly ATTR_NEW_TAX_ID_1 = 'ATTR_NEW_TAX_ID_1';
  static readonly ATTR_NEW_TAX_ID_2 = 'ATTR_NEW_TAX_ID_2';
  static readonly ATTR_NEW_TAX_ID_3 = 'ATTR_NEW_TAX_ID_3';

  static readonly ATTR_NEW_FSA_AMOUT = 'ATTR_NEW_FSA_AMOUT';
  static readonly ATTR_NEW_FSA_VALIDFROM = 'ATTR_NEW_FSA_VALIDFROM';
  static readonly ATTR_NEW_FSA_VALIDTO = 'ATTR_NEW_FSA_VALIDTO';
  static readonly ATTR_NEW_FSA_PRECOND_YEAR = 'ATTR_NEW_FSA_PRECOND_YEAR';
  static readonly ATTR_NEW_JOINTFSADATA = 'ATTR_NEW_JOINTFSADATA';
  // static readonly ATTR_NEW_FSA_ACK_PARTNER_INFORMED = 'ATTR_NEW_FSA_ACK_PARTNER_INFORMED';

  static readonly ATTR_VVTRESET_STOCKS = 'ATTR_VVTRESET_STOCKS';
  static readonly ATTR_VVTRESET_OTHER = 'ATTR_VVTRESET_OTHER';

  static readonly ATTR_KRYPTO_ADDRESS_CONFIRMED_DATETIME = 'ATTR_KRYPTO_ADDRESS_CONFIRMED_DATETIME';

  static readonly ATTR_IDENTDOCUPDATE_VALIDUNTIL = 'ATTR_IDENTDOCUPDATE_VALIDUNTIL';
  static readonly ATTR_IDENTDOCUPDATE_CONFIRMVALID = 'ATTR_IDENTDOCUPDATE_CONFIRMVALID';
  static readonly ATTR_ATTACHMENT_FILEREFS = 'ATTR_ATTACHMENT_FILEREFS';

  static readonly ATTR_TERMINATION_REASON = 'ATTR_TERMINATION_REASON';

  static readonly ATTR_LOMBARD_CONFIRMED_DOCS = 'ATTR_LOMBARD_CONFIRMED_DOCS';

  additionalProperties: any; // = new Map<string, any>();
  caseHistory: TicketHistoryEntry[]; // = new Array<TicketHistoryEntry>();
}
