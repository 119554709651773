import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  readonly #httpClient = inject(HttpClient);

  public getTranslation(lang: string) {
    const { local, apihost } = environment;
    return this.#httpClient.get<Translation>(`${local ? '' : apihost}/assets/i18n/${lang}.json`);
  }
}
