export enum InstrumentType {
  Share = 'AKTIE',
  Etf = 'ETF',
  Funds = 'FONDS',
  Derivative = 'DERIVAT',
  Crypto = 'KRYPTO',
  Index = 'INDEX',
  Currency = 'WAEHRUNG',
  Commodity = 'ROHSTOFF',
}
