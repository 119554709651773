export class Account {
  ownerName: string;
  intradayAmount: number;
  accountNr: string;
  depotNr: string;
  buyingPower: number;
  transferPower: number;
  lastImportedSaldo: number;
  lastSaldoImportDate: Date;
  accountIban: string;
  overdrawn: boolean;
  transferPowerBlocked: number;
}
