export enum OrderStatus {
  Created = 'Created',
  Created_CreatedCancel = 'Created_CreatedCancel',
  Created_SentCancel = 'Created_SentCancel',
  Created_PendingCancel = 'Created_PendingCancel',
  Sent = 'Sent',
  Sent_CreatedCancel = 'Sent_CreatedCancel',
  Sent_SentCancel = 'Sent_SentCancel',
  Sent_PendingCancel = 'Sent_PendingCancel',
  Pending = 'Pending',
  Pending_CreatedCancel = 'Pending_CreatedCancel',
  Pending_SentCancel = 'Pending_SentCancel',
  Pending_PendingCancel = 'Pending_PendingCancel',
  Open = 'Open',
  Open_CreatedCancel = 'Open_CreatedCancel',
  Open_SentCancel = 'Open_SentCancel',
  Open_PendingCancel = 'Open_PendingCancel',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Expired = 'Expired',
  PartiallyFilled = 'PartiallyFilled',
  PartiallyFilled_CreatedCancel = 'PartiallyFilled_CreatedCancel',
  PartiallyFilled_SentCancel = 'PartiallyFilled_SentCancel',
  PartiallyFilled_PendingCancel = 'PartiallyFilled_PendingCancel',
  PartiallyFilled_Canceled = 'PartiallyFilled_Canceled',
  PartiallyFilled_Expired = 'PartiallyFilled_Expired',
  PartiallyFilled_Filled = 'PartiallyFilled_Filled',
  Filled = 'Filled',
}
