import { CognitoUser } from '@aws-amplify/auth';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { AuthError } from '@aws-amplify/auth/lib-esm/Errors';
import { Amplify, Logger } from 'aws-amplify';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { AuthState } from '../AuthState';

const logger = new Logger('AuthDecorator', environment.production ? 'INFO' : 'DEBUG');

function check(authState: Subject<AuthState>, Auth: AuthClass) {
  // check for current authenticated user to init authState
  Auth.currentAuthenticatedUser()
    .then((user: CognitoUser) => {
      logger.debug('has authenticated user', user);
      authState.next({ state: 'signedIn', user });
    })
    .catch((err: AuthError) => {
      logger.debug('no authenticated user', err);
      authState.next({ state: 'signedOut', user: null });
    });
}

function decorateSignIn(authState: Subject<AuthState>, Auth: AuthClass) {
  const _signIn = Auth.signIn;
  Auth.signIn = (username: string, password: string): Promise<unknown> =>
    _signIn
      .call(Auth, username, password)
      .then((user: CognitoUser) => {
        logger.debug('signIn success');
        if (!user.challengeName) {
          authState.next({ state: 'signedIn', user });
          return user;
        }

        logger.debug(`signIn challenge: ${user.challengeName}`);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          authState.next({ state: 'requireNewPassword', user });
        } else if (user.challengeName === 'MFA_SETUP') {
          authState.next({ state: 'setupMFA', user });
        } else if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          authState.next({ state: 'confirmSignIn', user });
        } else {
          logger.debug(`warning: unhandled challengeName ${user.challengeName}`);
        }
        return user;
      })
      .catch((err: AuthError) => {
        logger.debug('signIn error', JSON.stringify(err));
        throw err;
      });
}

function decorateSignOut(authState: Subject<AuthState>, Auth: AuthClass) {
  const _signOut = Auth.signOut;
  Auth.signOut = (): Promise<unknown> =>
    _signOut
      .call(Amplify.Auth)
      .then((data: unknown) => {
        logger.debug('signOut success');
        authState.next({ state: 'signedOut', user: null });
        return data;
      })
      .catch((err: AuthError) => {
        logger.debug('signOut error', err);
        throw err;
      });
}

function decorateSignUp(authState: Subject<AuthState>, Auth: AuthClass) {
  const _signUp = Auth.signUp;
  Auth.signUp = (username: string, password: string, email: string, phone_number: string): Promise<any> =>
    _signUp
      .call(Auth, username, password, email, phone_number)
      .then((data: unknown) => {
        logger.debug('signUp success');
        authState.next({ state: 'confirmSignUp', user: { username } });
        return data;
      })
      .catch((err: AuthError) => {
        logger.debug('signUp error', err);
        throw err;
      });
}

function decorateConfirmSignUp(authState: Subject<AuthState>, Auth: AuthClass) {
  const _confirmSignUp = Auth.confirmSignUp;
  Auth.confirmSignUp = (username: string, code: string): Promise<unknown> =>
    _confirmSignUp
      .call(Auth, username, code)
      .then((data: unknown) => {
        logger.debug('confirmSignUp success');
        authState.next({ state: 'signIn', user: { username } });
        return data;
      })
      .catch((err: AuthError) => {
        logger.debug('confirmSignUp error', err);
        throw err;
      });
}

export function authDecorator(authState: Subject<AuthState>, authModule: AuthClass) {
  check(authState, authModule);
  decorateSignIn(authState, authModule);
  decorateSignOut(authState, authModule);
  decorateSignUp(authState, authModule);
  decorateConfirmSignUp(authState, authModule);
}
