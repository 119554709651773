import { UntypedFormGroup } from '@angular/forms';
import { Asset } from './asset.model';

export class SavingsPlanFormModel {
  public type: SavingsPlanFormType;
  public hasSelectedInstrument: boolean;
  public selectedAsset: Asset;
  public execDates: Date[];
  public intervals: string[];
  public form: UntypedFormGroup;
}

export enum SavingsPlanFormType {
  ADD,
  EDIT,
}
