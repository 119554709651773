export enum CorporateActionType {
  BARABF = 'BARABF', // Barabfindung
  KAP = 'KAP', // Kapitalerhöhung mit Bezugsrechtehandel -- Nur diese ist relevant!
  KAP2 = 'KAP2', // Kapitalerhöhung ohne Bezugsrechtehandel
  UMTAUSCHF = 'UMTAUSCHF', // Umtauschangebot mit Baranteil
  UMTO = 'UMTO', // Umtauschangebot ohne Baranteil
  WDIF = 'WDIF', // Wahldividende
  WDIV = 'WDIV', // Wahldividende
  ZEICHNUNG = 'ZEICHNUNG', // Bezugsangebot ohne Bezugsrechte
  TEILRECHT = 'TEILRECHT', // SPEZIALFALL Teilrecht
}
