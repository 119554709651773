import { ISODateTimeUTC } from './iso-date-time.model';

export class HistoricQuote {
  id?: number;
  isin?: string;
  ask?: number;
  bid?: number;
  ts?: ISODateTimeUTC;

  constructor(id?: number, isin?: string, ask?: number, bid?: number, ts?: ISODateTimeUTC) {
    this.id = id;
    this.isin = isin;
    this.ask = ask;
    this.bid = bid;
    this.ts = ts;
  }
}
