import { BackendErrorResponse } from './backend-error-response.model';

export interface IBackendResponse<T> {
  errorCode?: BackendResponseErrorCode;
  status?: BackendResponseStatus;
  errorMessage?: string;
  err?: BackendErrorResponse;
  data?: T;
  success?: boolean;
  message?: string;
}

export type BackendResponseErrorCode = 'PRICEALERTS_MAX_ITEMS_REACHED';

export enum BackendResponseStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  NOT_ACCEPTABLE = 'NOT_ACCEPTABLE',
}
