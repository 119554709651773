import { HttpErrorResponse } from '@angular/common/http';
import { BackendErrorResponse, BackendResponseStatus, HttpInterceptorParams, IBackendResponse } from '@zero/shared/util-models';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class HttpResponseHandleHelper {
  public static handleResponse<T>(httpResponseObservable: Observable<T>): Observable<IBackendResponse<T>> {
    return httpResponseObservable.pipe(
      map((response: T) => {
        return {
          status: BackendResponseStatus.SUCCESS,
          success: true, //TODO: cleanup success usages
          data: response,
        } as IBackendResponse<T>;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        const backendError = HttpResponseHandleHelper.getBackendErrorFromHttpError(errorResponse);
        return of({
          status: BackendResponseStatus.ERROR,
          success: false, //TODO: cleanup success usages
          errorMessage: HttpResponseHandleHelper.getMessageFromError(backendError),
          err: backendError,
        } as IBackendResponse<T>);
      }),
    );
  }

  public static handleResponseP<T>(httpResponseObservable: Observable<T>): Promise<IBackendResponse<T>> {
    return firstValueFrom(HttpResponseHandleHelper.handleResponse(httpResponseObservable));
  }

  public static getBackendErrorFromHttpError(httpErr: HttpErrorResponse): BackendErrorResponse {
    if (httpErr.error && httpErr.status !== 503) {
      if (typeof httpErr.error === 'string') {
        return JSON.parse(httpErr.error) as BackendErrorResponse;
      } else {
        return httpErr.error as BackendErrorResponse;
      }
    } else {
      return {
        message: httpErr.message,
        status: httpErr.status.toString(),
        errors: [httpErr.error],
      } as BackendErrorResponse;
    }
  }

  public static getMessageFromError(err: BackendErrorResponse, defaultMessage?: string): string {
    if (err?.message) {
      return err.message;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return defaultMessage;
    }
  }

  public static skipGlobalHandler(): HttpInterceptorParams {
    return new HttpInterceptorParams({ skipInterceptorErrorHandler: true });
  }
}
