import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #httpClient = inject(HttpClient);
  readonly #trackingUrl = `${environment.apihost}/api/user/tracking`;

  public listenToTrackingParams = (): void => {
    this.#activatedRoute.queryParams
      .pipe(filter(({ fzsrc }) => !!fzsrc))
      .subscribe(({ fzsrc, fzmedium, fzcampaign, fzcontent, fzterm, fzterms }) => {
        fzterms
          ? this.#trackMultiple(fzsrc, fzmedium, fzcampaign, fzcontent, fzterms.split(','))
          : this.trackSingle(fzsrc, fzmedium, fzcampaign, fzcontent, fzterm);
      });
  };

  public trackSingle = (fzsrc: string, fzmedium: string, fzcampaign: string, fzcontent: string, fzterm: string) =>
    this.#track({ fzsrc, fzmedium, fzcampaign, fzcontent, fzterm });

  #trackMultiple = (fzsrc: string, fzmedium: string, fzcampaign: string, fzcontent: string, fzterms: string[]) =>
    this.#track({ fzsrc, fzmedium, fzcampaign, fzcontent, fzterms });

  #track = (body: unknown) => this.#httpClient.post(this.#trackingUrl, body).subscribe();
}
