export class Post {
  id: string;
  title: string;
  readAt: Date;
  read: boolean;
  archived: boolean;
  mifid: boolean;
  keywords: string;
  date: string;
  pdfTitle: string;
  pdfIsin: string;
  pdfWkn: string;
  pdfOrderDateTime: string;
  pdfText: string;

  public getDisplayTitle(): string {
    if (this.pdfTitle) {
      let ret = this.pdfTitle;
      if (this.pdfIsin) {
        const isins = this.pdfIsin.split(',');
        if (isins.length > 2) {
          ret += ` ${isins[0]}, ${isins[1]}, ...`;
        } else {
          ret += ` ${isins.join(', ')}`;
        }
      }
      return ret;
    } else {
      return this.title;
    }
  }
}

export type PostCategories = 'P' | 'A' | 'M';
