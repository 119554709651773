import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class UserMessage {
  message: string;
  msgType: string;
  deleteWithNextRouteEvent: boolean;
  deleteTimeStamp: number;

  constructor(message: string, msgType: string, deleteWithNextRouteEvent?: boolean, deleteAfterMS?: number) {
    this.message = message;
    this.msgType = msgType;
    this.deleteWithNextRouteEvent = deleteWithNextRouteEvent;
    this.deleteTimeStamp = deleteAfterMS ? new Date().getTime() + deleteAfterMS : 0;
  }

  getSanitizedMessage(sanitizer: DomSanitizer): SafeHtml {
    return sanitizer.bypassSecurityTrustHtml(this.message);
  }
}
